body {
  font-family: "Helvetica";
  color: #272b41;
  font-size: 14px;
  line-height: 16px;
}

.link-selected {
  color: #586b94;
  background: #f1f6ff;
}

.form-wrapper {
  margin-top: 48px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-top: 32px;
}

.form-wrapper form {
  width: 650px;
}

.form-wrapper-centered {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1200px) {
  .form-wrapper-centered {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .form-wrapper-centered {
    position: fixed;
    width: 80%;
  }
}

.form-wrapper-margin-top {
  margin-top: 20px;
}

.form-CTA-Flex-Col {
  display: flex;
  flex-direction: column;
}

.margin-bottom-adjust {
  margin-bottom: -30px;
}

.project-modal .ant-form-item-control-input {
  margin-top: 10px;
  min-height: auto;
}

.project-modal .ant-form-item-control-input {
  margin-top: 10px;
  min-height: auto;
}

.project-modal-footer {
  padding: 10px !important;
  text-align: left !important;
}

.delete-modal {
  width: 420px !important;
}

.delete-modal-content {
  display: flex;
  color: #5a5f7d;
  flex-direction: column;
  align-items: center;
}

.delete-modal-content img {
  margin-bottom: 33px;
  width: 94px;
}

.delete-modal-buttons:first-child {
  border: none;
  background-color: #f58266;
  color: white;
}

.delete-modal-buttons:last-child {
  border: none;
  margin-left: 10px;
  background-color: #f1f7ff;
  color: #39455e;
}

.ant-radio-vertical .ant-radio-wrapper {
  display: block;
}

.ant-radio-vertical .ant-radio-wrapper:not(:last-child) {
  margin-bottom: 14px;
}

.ant-radio-vertical .ant-radio-wrapper:not(:last-child) {
  margin-bottom: 14px;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #586b94 !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #586b94;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #586b94;
}

table tbody td .user-info .user-name {
  font-size: 14px;
}

table tbody td span.status-text {
  font-size: 12px;
  padding: 0 12.41px;
  line-height: 1.9;
  font-weight: 500;
  border-radius: 12px;
  text-transform: capitalize;
  display: inline-block !important;
  background: #ddd;
}

table tbody td span.status-text.active {
  background-color: #20c997;
  color: #20c997;
}

table tbody td span.status-text.live {
  background-color: #20c997;
  color: #20c997;
}

table tbody td span.status-text.disabled {
  background-color: #f6f6f6;
  color: #afafaf;
}

table tbody td span.status-text.invited {
  background-color: #fa8b0c;
  color: #fa8b0c;
}

.ant-table-pagination.ant-pagination {
  width: 100%;
  text-align: "left";
  border-top: 1px solid #f1f2f6;
  margin-top: 0 !important;
  padding-top: 30px;
}

@media only screen and (max-width: 767px) {
  .ant-table-pagination.ant-pagination {
    text-align: center;
  }
}

.blue-ish {
  color: #9299bb;
}

.color-blue-gray {
  color: #adb4d2;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

@media only screen and (max-width: 400px) {
  .error-img {
    width: 80%;
  }
}

.text-align-center {
  text-align: center;
}

.full-height {
  height: 100%;
}

.account-settings-card.ant-card {
  border-radius: 16px;
  box-shadow: 0px 5px 20px rgba(146, 153, 184, 0.031);
}

.account-settings-card.ant-card .setting-card-title div {
  margin-bottom: 0;
}

.account-settings-card.ant-card .setting-card-title span {
  margin-left: 0;
  font-size: 13px;
}

.account-settings-card.ant-card .ant-card-body {
  padding: 25px 0 25px !important;
}

.account-settings-card.ant-card .author-info {
  padding: 0 20px 20px;
  text-align: center;
  border-bottom: 1px solid #f1f2f6;
}

.account-settings-card.ant-card .author-info .info {
  background-color: transparent;
}

.account-settings-card.ant-card .img-container {
  position: relative;
  max-width: 120px;
  height: 120px;
  margin: 0 auto 18px;
}

.account-settings-card.ant-card .img-container .user-profile-img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.account-settings-card.ant-card .img-container .ant-upload-select {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.account-settings-card.ant-card .img-container .ant-upload-select span {
  display: inline-flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  background: #586b94;
}

.account-settings-card.ant-card .img-container .ant-upload-select a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.account-settings-card.ant-card figcaption .info h1,
.account-settings-card.ant-card figcaption .info h2,
.account-settings-card.ant-card figcaption .info h3,
.account-settings-card.ant-card figcaption .info h4,
.account-settings-card.ant-card figcaption .info h5,
.account-settings-card.ant-card figcaption .info h6 {
  font-size: 18px;
  margin-bottom: 4px;
}

.account-settings-card.ant-card figcaption .info p {
  margin-bottom: 0;
  color: #9299b8;
}

.account-settings-card.ant-card .settings-list {
  padding: 20px 20px 0px 20px;
}

.account-settings-card.ant-card .settings-list li a {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  border-radius: 6px;
  color: #9299b8;
}

.account-settings-card.ant-card .settings-list li a i,
.account-settings-card.ant-card .settings-list li a svg,
.account-settings-card.ant-card .settings-list li a img {
  margin-right: 13px;
}

.account-settings-card.ant-card .settings-list li a.active {
  font-weight: 500;
  color: #586b94;
  background: #f1f6ff;
  border-radius: 4px;
}

.settings-form-wrapper {
  padding: 0 24px;
}

.settings-form-wrapper .input-message {
  font-size: 13px;
  color: #9299b8;
  margin: -22px 0 24px;
}

.panel.ant-collapse-item {
  background-color: #ffffff;
  border-radius: 16px !important;
  margin-bottom: 32px !important;
  overflow: hidden;
  border: 0;
  box-shadow: 0px 5px 20px rgba(146, 153, 184, 0.031);
}

.panel.ant-collapse-item .ant-collapse-header {
  padding: 24px !important;
}

.panel.ant-collapse-item .panel-header__text p {
  margin-top: -8px;
  color: #9299b8;
  margin-bottom: 0;
}

.panel.ant-collapse-item .ant-collapse-content-box {
  padding: 0 !important;
  border-top-width: 1px;
  border-top-color: #f1f2f6;
  border-top-style: solid;
}

.panel.ant-collapse-item .ant-collapse-content-box .panel-body {
  margin: 20px 0;
}

.top-providers-wrapper > .ant-card {
  min-height: 440px;
}

.top-providers-wrapper .top-providers-wrap {
  margin-top: 18px;
}

.top-providers-wrapper
  .top-providers-wrap
  .top-providers-table
  .provider-author {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.top-providers-wrapper
  .top-providers-wrap
  .top-providers-table
  .provider-author
  img {
  border-radius: 50%;
}

.top-providers-wrapper
  .top-providers-wrap
  .top-providers-table
  .provider-author
  .provider-author-info {
  margin-left: 12px;
}

.top-providers-wrapper
  .top-providers-wrap
  .top-providers-table
  .provider-author
  .provider-author-info
  h4 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 2px;
}

.top-providers-wrapper
  .top-providers-wrap
  .top-providers-table
  .provider-author
  .provider-author-info
  p {
  font-size: 13px;
  color: #9297af;
  margin-bottom: 0;
}

.top-providers-wrapper .top-providers-wrap .top-providers-table .app-count {
  font-size: 14px;
}

.top-providers-wrapper
  .top-providers-wrap
  .top-providers-table
  .ant-table-tbody
  > tr
  > td {
  padding: 7px 25px;
  border-bottom: 0 none;
}

.services-wrapper > .ant-card {
  min-height: 440px;
}

.services-wrapper .services-chart-inner div {
  position: relative;
}

.services-wrapper .services-chart-inner p {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  width: 200px;
  margin-bottom: 0;
  display: inline-block;
  transform: translate(-50%, -50%);
}

.services-wrapper .services-chart-inner p span {
  font-size: 24px;
  display: block;
  font-weight: 600;
}

.panel-table table thead tr th {
  font-weight: bold;
  border: 0;
  background-color: #ffffff;
}

.panel-table table thead tr th:last-child {
  padding: 10px 0;
}

.panel-table table thead tr th:not(:last-child) {
  padding: 10px 24px;
}

.panel-table table tbody td {
  border: 0;
  overflow: hidden;
}

.panel-table table tbody td:last-child {
  padding: 10px 0;
}

.panel-table table tbody td:not(:last-child) {
  padding: 10px 24px;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.services-wrap {
  max-width: 400px;
  margin: 42px auto auto;
}

@media only screen and (max-width: 406px) {
  .services-wrap {
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }
}

.services-wrap > div {
  width: 33.33%;
  text-align: center;
}

@media only screen and (max-width: 406px) {
  .services-wrap > div {
    width: 90%;
  }
}

.services-wrap > div span {
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
}

@media only screen and (max-width: 1300px) {
  .services-wrap > div span {
    display: inline-block;
  }
}

@media only screen and (max-width: 406px) {
  .services-wrap > div span {
    display: block;
    font-size: 14px;
  }
}

.services-wrap > div sub {
  bottom: 0;
  left: 10px;
  font-size: 13px;
  color: #9299bb;
}

.services-wrap .service-single {
  text-align: center;
}

@media only screen and (max-width: 406px) {
  .services-wrap .service-single {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .services-wrap .service-single div {
    min-width: 60%;
  }
  .services-wrap .service-single div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.form__updtaed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.form__updtaed h1 {
  margin: 50px 0 30px;
}

.form__updtaed h3 {
  max-width: 80%;
  line-height: 25px;
}

.loc-table .ant-table .ant-table-row {
  cursor: pointer;
}

.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-options > .ant-select > .ant-select-selector,
.ant-pagination .ant-pagination-item {
  border-radius: 8px;
}

.text-center {
  text-align: center;
}
