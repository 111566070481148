.auto-complete-input {
  display: block !important;
  margin-top: 24px;

  :global {
    .ant-select-selection-placeholder {
      padding: 0 50px !important;
      text-align: left;
      left: 2px !important;

      @media only screen and (max-width: 575px) {
        width: 15rem;
      }
    }

    .ant-select-selector {
      display: flex;
      align-items: center;
      height: 37px !important;
      padding: 0 !important;
      border-color: #E3E6EF !important;

      .ant-select-selection-search {
        .ant-input-affix-wrapper {
          width: 300px;
          padding: 0 20px;
        }

        .ant-input-prefix {

          svg,
          i {
            color: #7089BE;
          }
        }
      }
    }

    .ant-select-selector input {
      height: 33px !important;
      padding-left: 0 !important;
    }

    .ant-select-selection-search {
      left: 20px;
      width: auto !important;

      @media only screen and (max-width: 575px) {
        width: 100% !important;
      }
    }

    .ant-select-selection-search textarea.ant-input {
      padding: 12px 20px;
    }

    .ant-input-affix-wrapper {
      background-color: #F6F6F6;
      padding: 0 20px;
    }

    .ant-input-affix-wrapper input {
      background-color: #F6F6F6;
      height: 38px !important;
    }

    .ant-input-affix-wrapper .ant-input-prefix .search-btn {

      height: 38px;
      border-radius: 0 4px 4px 0;

      svg,
      i {
        color: #fff;
      }
    }
  }
}