.add-tip {
  border-radius: 100px;
  background-color: #ffc043;
  color: #000000;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  font-weight: 500;
  font-size: 12px;
  word-break: keep-all;
  white-space: nowrap;
}

.edit-tip {
  border-radius: 100px;
  background-color: #f1f6ff;
  color: #586b94;
  display: flex;
  align-items: center;
  word-break: keep-all;
  padding: 6px 12px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
}

.providers-row {
  @media only screen and (max-width: 450px) {
    p {
      font-size: 13px;
    }
  }

  @media only screen and (max-width: 400px) {
    p {
      font-size: 12px;
    }
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.pro-list {
  p {
    font-size: 14px;
  }
}

.time-picker {
  max-width: 105px;

  @media only screen and (max-width: 550px) {
    min-width: 8em;
    margin-left: -20px;
  }

  @media only screen and (max-width: 480px) {
    min-width: 6em;
    margin-left: -20px;
    font-size: 11px;

    padding-left: 4px;
    padding-right: 4px;
  }

  @media only screen and (max-width: 385px) {
    min-width: 6em;
    margin-left: -20px;
    font-size: 10px;
    padding-left: 4px;
    padding-right: 4px;
  }

  input {
    @media only screen and (max-width: 550px) {
      font-size: 12px;
    }

    @media only screen and (max-width: 450px) {
      font-size: 11px;
    }

    @media only screen and (max-width: 385px) {
      font-size: 10px;
    }
  }
}

.panel-time-picker {
  :global {
    .ant-picker-ranges {
      .ant-picker-ok {
        display: block;
        float: none;
        margin-left: 0px;

        button span {
          display: none;
        }

        button.ant-btn.ant-btn-primary.ant-btn-sm {
          width: 100%;
          background-color: #f1f7ff;
          border: none;
          height: 40px;
          font-weight: 500;
          font-size: 14px;
          border-radius: 4px;
          color: #39455e;

          &::after {
            content: "Save";
          }

          span::before {
            content: "";
          }
        }
      }
    }

    .ant-picker-footer-extra {
      padding: 4px 6px;

      button {
        width: 100%;
      }
    }
  }
}

.stars {
  font-size: 16px;
  color: #ffc043;
  margin: 0px 15px 0px 0px;

  @media only screen and (max-width: 600px) {
    margin: 0px 15px 0px 0px;

    svg {
      width: 13px;
    }
  }

  @media only screen and (max-width: 450px) {
    margin: 4px;

    svg {
      width: 10px;
    }
  }
}

.min-picker {
  margin-left: 5px;
  max-width: 42px;
  margin-right: 5px;

  :global {
    .ant-picker-suffix {
      display: none !important;
    }
  }
}

.section-title {
  font-weight: 500;

  @media only screen and (max-width: 450px) {
    font-size: 14px;
  }
}

.booked-by {
  font-weight: 500;
}

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(255, 255, 255, 0.7);
}

.needsAttention-warning {
  color: #c11500;
  padding: 8px;
  padding-bottom: 20px;
}

.scrollbox {
  max-height: calc(100vh - 225px);
  min-height: 100px;
  overflow-y: scroll;
  width: 102.8%;
}

.tabs {
  margin-top: -15px;

  :global {
    .ant-tabs-nav {
      color: #757575;
      font-weight: 500;

      &:hover,
      &:active {
        color: #757575;
      }

      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            padding: 8px 10px;
          }

          .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
            background: #586b94;
            height: 5px;
          }

          .ant-tabs-tab {
            &:hover {
              color: #586b94;
            }

            &.ant-tabs-tab-active {
              .ant-tabs-tab-btn {
                color: #586b94;
              }
            }
          }
        }
      }

      .ant-tabs-content-holder {
        margin-top: 20px;
      }

      &:before {
        border-bottom: 5px solid #e2e2e2;
      }
    }
  }
}

.send-offer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
  margin-right: 8px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }

  :global {
    .ant-select:not(.ant-select-customize-input):not(.post-shift-select) .ant-select-selector {
      min-height: 48px !important;
    }
    .ant-select.post-shift-select .ant-select-clear {
      margin-top: -10px;
    }

  }
}

.appointment-select {
  width: 100%;

  :global {
    .ant-select-selector {
      border-color: #e2e2e2 !important;
      border-radius: 5px !important;
    }

    .ant-select-selection-placeholder {
      color: #757575;
      font-size: 14px !important;
      font-weight: 500 !important;
    }
    .ant-select.post-shift-select .ant-select-selection-placeholder {
      color: #757575;
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 20px;
    }

    .ant-select-clear {
      width: 20px;
      font-size: 20px;
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;
      height: 35px;
      padding: 5px 5px;
    }
  }
}

footer.view-appt__footer {
  border-top: 1px solid #f1f2f6;
  text-align: end;
  padding-top: 18px;
  margin-bottom: -5px;
}

.nb-of-providers {
  margin-left: 6px;
  border: 1px solid #e2e2e2;
  padding: 3px 10px;
  text-align: center;
  border-radius: 3px;
  margin-top: -5px;
}

.plus-icon {
  background-color: #eeeeee;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  padding: 3px;
  margin-left: 8px;
  cursor: pointer;
}

.view-modal_section-title {
  color: #586b94;
}

.break-input {
  width: 40px;

  :global {
    .ant-input-number-input {
      padding: 0 6px;
      height: 27px;
      text-align: center;
    }

    & .ant-input-number-handler-wrap {
      display: none;
    }
  }
}

.save-btn {
  color: #39455e !important;
  background-color: #f1f7ff !important;
}
.save-btn[disabled] {
  color: #AFAFAF !important;
  background-color: #F6F6F6 !important;
}

.break-dropdown-overlay {
  width: fit-content;
  padding: 2px 5px !important;
  margin: 0 !important;
  box-shadow: 0px 5px 20px rgba(146, 153, 184, 0.031);

  ul {
    min-width: 100% !important;
    box-shadow: 0px 5px 20px rgba(146, 153, 184, 0.031);
  }
}

.shift-form {
  padding: 0;
  margin: 0;

  :global {
    .ant-form-item {
      margin: 0;
    }

    .ant-form-item-control-input .ant-picker {
      padding: 4px 11px;
    }
  }
}

.confirm-Modal-canecl-btn {
  border-color: #f1f6ff;
  background-color: #f1f6ff;
  color: #39455e;
  margin: 10px 0;
}

.modal-container {
  :global {
    .ant-modal {
      border-radius: 16px;

      .ant-modal-content {
        border-radius: 16px;

        .ant-modal-close {
          >span>span {
            background-color: #f4f5f7;
            padding: 8px;
            border-radius: 50%;
            font-size: 13px;
          }
        }

        .ant-modal-header {
          border-top-left-radius: 16px;
          border-top-right-radius: 16px;

          >div {
            font-weight: 500;
          }
        }
      }
    }
  }
}

th[rowspan].rm-style {
  vertical-align: top;
}

th.rm-style {
  border-bottom: 0px !important;
  background: none !important;
}
th.small-padding-left {
  padding: 4px 8px !important;
  text-align: left!important;
  font-size: 14px;
  line-height: 16px;
  color: #545454!important;
  font-style: normal;
  font-weight: 500;
}
th:first-child.small-padding-left {
  padding: 4px 0px !important;
}

tr {
  td.rm-style {
    border-bottom: 0px !important;
    padding: 4px 8px;
  }
  td.rm-style:first-child, td.rm-style:last-child {
    border-bottom: 0px !important;
    padding: 4px 0px;
  }
}
.search-input {
  height: 44px;
  width: 100%;
  border-radius: 5px;

  @media screen and (max-width: 991px) {
    width: 100% !important;
  }
}
.search-input::placeholder {
  color: #9299B8;
}