@use "../../../styles/abstracts" as *;

.switch-btn__container {
  .switch-btn {
    margin-left: 8px;
  }

  :global {
    .ant-switch-checked {
      background-color: $navy-accent;
    }
  }
}