.gray-row {
    background-color: #f6f6f6; /* Gray background color */
}
.white-row {
    background-color: #ffffff; /* White background color */
}
.error-row {
    background-color: #fff6f6;
}
.br-btm-0 {
    border-bottom: 0px!important;
}
tr.noBorderLastRow:last-child td {
    border-bottom: 0px!important;
}