.roles-select {
  width: 100%;

  :global {
    .ant-select-selector {
      border-radius: 5px !important;
      border-color: #e2e2e2 !important;
      padding: 10px !important;
      min-height: 55px !important;
    }

    .ant-select-selection-placeholder {
      color: #9299b8;
    }

    .ant-select-arrow {
      width: 20px;
      color: #323232 !important;
    }
    .ant-select-clear {
      width: 20px;
      font-size: 20px;
    }
    .ant-select-selection-item {
      height: fit-content;
      padding: 5px 3px;
    }
  }
}

.card-container {
  background-color: white;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0px 5px 20px rgba(146, 153, 184, 0.102);
}
