:global {
  .ant-popover-inner-content {
    padding: 0 !important;
  }
}

.content {

  a,
  .span {
    display: block;
    color: #888;
    padding: 6px 12px;
    text-align: left;

    span {
      padding-left: 12px;
    }
  }

  a:hover {
    background: #586b9410;
    color: #586b94;
  }
}

.title {
  text-align: left;
  margin: 0;
}