.modal-wrapper {
  :global {
    .ant-modal-content {
      border-radius: 16px !important;

      .ant-modal-close {
        padding: 7px !important;

        > span > span {
          background-color: #f4f5f7;
          padding: 8px;
          border-radius: 50%;
          font-size: 13px;
        }
      }

      .ant-modal-header {
        border: none;
        padding: 16px 24px !important;
        border-radius: 16px 16px 0 0 !important;

        > div {
          font-size: 18px;
          font-weight: 500;
        }
      }

      .ant-modal-body {
        padding-top: 0 !important;
        max-height: 75vh;
        overflow-y: scroll;
        overflow-x: hidden;
        button:first-child {
          // background-color: #f1f6ff;
          margin-right: 10px;
        }
      }
    }
  }
}

.form-container {
  :global {
    .ant-form.ant-form-vertical {
      .ant-input {
        padding: 16px 10px;
      }
    }
  }
}

.title {
  &.ant-row {
    padding: 0;
  }

  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.file-versions-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  color: #000;
  font-size: 12px;
  max-width: 100%;
  max-height: 75vh;
  overflow: auto;
  text-overflow: ellipsis;
  font-family: "Poppins", sans-serif;

  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.version-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  :nth-child(1) {
    font-size: 14px;
    font-weight: 500;
    color: var(--light-mode-content-primary, #000);
  }
}

.file-download-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  div {
    color: #898da5;
    font-size: 12px;
    font-weight: 400;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: keep-all;
    white-space: nowrap;
  }

  span {
    margin-left: 5px;
  }
}

.open-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  div {
    color: #898da5;
    font-size: 12px;
    font-weight: 400;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: keep-all;
    white-space: nowrap;
  }
  span {
    margin-left: 5px;
  }
  span svg {
    padding-top: 5px;
  }
}

.anitmation {
  transition: all 0.5s ease-in-out;
}
