.wrapper {
  .page-header-actions button.ant-btn-white svg {
    width: 12px;
    height: 12px;
    margin-right: 2px;
    color: #586b94;
  }
  i + span,
  svg + span,
  img + span {
    margin-right: 6px;
  }
}
