@use "../../../styles/abstracts" as *;

.modal {
  width: 420px !important;

  :global {
    .ant-modal-body {
      height: auto;
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      padding: 30px;
      p {
        text-align: center;
        color: #5a5f7d;
      }

      footer * {
        margin: 5px;
      }

      button:first-child {
        background-color: #e11900;
      }

      button:last-child {
        color: #39455e;
        background-color: #f1f7ff;
      }
    }
  }
}

.edit-header-wrapper {
  :global {
    .header-wrapper {
      @media only screen and (max-width: 815px) {
        display: flex;
        flex-direction: column;
        align-items: left;
      }
    }

    .buttons-wrapper {
      @media only screen and (max-width: 815px) {
        min-width: fit-content;
        padding-top: 20px;
      }

      @media only screen and (max-width: 400px) {
        flex-direction: column;
        align-items: center;
        min-width: 100vw;
        gap: 10px;
      }

      display: flex;
      justify-content: flex-end;
      padding: 40px;
    }

    .edit-btn-add-new {
      margin-left: 10px;
    }

    .edit-btn-add-new:hover {
      background-color: #586b94;
    }

    .card-nav {
      padding: 40px;
      padding-top: 0;
      padding-bottom: 20px;
      width: 100%;

      ul {
        flex-wrap: wrap;
        margin-bottom: -4px -10px;

        @media only screen and (max-width: 575px) {
          justify-content: center;
        }

        li {
          &.deactivate {
            svg,
            img,
            i,
            span {
              color: #757575 !important;
              transition: all 0.9s ease;

              &:hover {
                color: #39455e !important;
              }
            }
          }

          &.active {
            &:after {
              opacity: 1;
              visibility: visible;
              border-radius: 50px;
            }

            button,
            svg,
            img,
            i,
            span {
              color: primary-color !important;
            }
          }

          // }
        }
      }

      li.active:before {
        bottom: -6px;
        border-radius: 50px;
        height: 3px;
      }
    }

    hr {
      height: 3px;
      background-color: rgba(0, 0, 0, 0.08);
      border-radius: 50px;
      border: none;
      margin-top: 3px;
    }

    .select-wrapper {
      @media only screen and (max-width: 815px) {
        padding-bottom: 0px;
      }

      display: flex;
      padding: 40px;
    }

    .preview-icon {
      width: 20px;
      height: 20px;
    }

    .edit-btn-preview-soothe:focus {
      background-color: #fff !important;
    }
  }
}

.footer-wrapper {
  width: 100%;

  :global {
    .step-action-wrap {
      display: flex;
      justify-content: center;

      .step-action-inner {
        width: 580px;
        padding: 0 25px;

        @media only screen and (max-width: 575px) {
          width: 100%;
          padding: 0;
        }
      }
    }

    .ant-form-item {
      margin: 0;
    }

    .steps-action {
      margin-top: 1rem;
      width: 100%;
      float: right;
      display: flex;

      @media only screen and (max-width: 991px) {
        margin-top: 25px;
      }

      @media only screen and (max-width: 379px) {
        flex-flow: column;
      }

      .create-mode-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      .cancel-button {
        margin-left: 13px;
      }

      .ant-switch-checked {
        background-color: #586b94;
      }

      .button {
        display: flex;
        align-items: center;
        height: 44px;
        padding: 0 20px;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;

        @media only screen and (max-width: 379px) {
          justify-content: center;
        }

        &.ant-btn-light {
          border: 1px solid $border-color-light;
        }

        &.btn-next {
          svg {
            margin-left: 10px;
          }
        }

        &.btn-prev {
          svg {
            margin-right: 10px;
          }
        }
      }

      .ant-btn-primary {
        border-color: #f58266;
        background-color: #f58266;
      }

      .ant-btn-secondary {
        border-color: #f1f6ff;
        background-color: #f1f6ff;
        color: #39455e;
      }
    }

    .save-action {
      display: flex;

      @media only screen and (max-width: 467px) {
        flex-direction: column;
        flex-direction: column-reverse;
      }
    }

    .submit-button {
      margin-left: 16px;

      @media only screen and (max-width: 467px) {
        margin-left: 0px;
      }
    }
  }
}

.styled-input {
  :global {
    .ant-input-affix-wrapper {
      border-radius: 20px;
      background-color: #f6f6f6;
      border-color: #f6f6f6;
    }

    .ant-input {
      background-color: #f6f6f6;
      outline: none;
      padding: 10px 5px !important;
    }

    .ant-input-affix-wrapper {
      padding: 0 20px;
    }
  }
}
