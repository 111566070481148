.main-container {
  padding: 24px;
}
.card {
  // padding: 10px;
  background-color: #ffffff;
  border-radius: 16px;
}
.header-boxed {
  padding-bottom: 0px !important ;
}
