@use "../../../styles/abstracts" as *;

.filter-menu {
  margin-top: 24px;
  margin: -10px;
  color: $navy-accent;

  @media only screen and (max-width: 991px) {
    margin-top: 20px;
  }
  
  .ant-radio-button-wrapper {
    height: 40px;
    line-height: 40px;
    padding: 0 12.5px;

    &.active {
      span {
        color: $navy-accent;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    text-align: center;
  }

  .toolbox-menu-title,
  .ant-radio-group-outline {
    margin: 10px;
  }
}