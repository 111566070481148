.filters-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 900px) {
    display: block;
  }
}

.sort-container {
  width: 33%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: 900px) {
    width: 100%;
  }
}

.sort-select {
  width: 100%;

  @media only screen and (max-width: 900px) {
    width: 100%;
  }

  :global {
    .ant-select-selector {
      min-height: 48px !important;
      border: 1px solid #E2E2E2 !important;
      border-radius: 5px !important;
      padding: 6px !important;

      .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
        margin-left: 10px;
      }
    }
  }
}

.sort-by {
  margin-right: 10px;
  font-weight: 400;
  font-size: 14px;
  color: #272B41;
  word-break: keep-all;
  white-space: nowrap;
}

.search-container {
  width: 33%;
  @media only screen and (max-width: 900px) {
    width: 100%;
    margin-top: 10px;
  }
  :global {
    .ant-select {
      margin: 0;

      .ant-select-selector {
        height: 45px !important;

        .ant-select-selection-search {
          width: 100% !important;
          @media only screen and (max-width: 900px) {
            width: 100% !important;
          }
        }

        .ant-select-selection-placeholder {
          margin-left: -10px;
        }

        .ant-input-affix-wrapper.ant-select-selection-search-input {
          background-color: white;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 48px;
          border: 1px solid #E2E2E2;
          border-radius: 5px;
          width: 100% !important;

          @media only screen and (max-width: 900px) {
            width: 100% !important;
          }

          .ant-input {
            height: 100% !important;
            background-color: white;
          }
        }
      }
    }
  }
}