@use "../../../styles/abstracts" as *;

.wrapper {
  display: flex;
  flex-direction: column;
  :global {
    .rdrDateDisplay,
    .rdrMonthAndYearPickers {
      display: none;
    }
    .rdrMonth {
      position: relative;
      max-width: 272px;
    }
    .rdrMonthName {
      text-align: center;
      font-size: 18px;
      position: absolute;
      top: -50px;
      left: 100px;
      font-weight: 400;
    }
    .rdrCalendarWrapper.calendar-wrapper {
      .rdrNextPrevButton {
        background: none;
      }
      .rdrMonthsHorizontal {
        .rdrMonth {
          .rdrMonthName {
            font-size: 14px;
            font-weight: 500;
            color: $dark-color;
          }
        }
        .rdrDays {
          .rdrDay {
            &.rdrDayHovered {
              border: 0 none;
              border-radius: 50%;
              background-color: $navy-accent;
              .rdrDayStartPreview.rdrDayEndPreview {
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
              }
              .rdrDayNumber {
                &:after {
                  display: none;
                }
                span {
                  color: #fff;
                  &:after {
                    display: none;
                  }
                }
              }
            }
            .rdrDayStartPreview.rdrDayEndPreview {
              display: none;
            }
            .rdrSelected {
              background-color: $navy-accent;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
            }
            .rdrSelected ~ .rdrDayNumber span {
              color: #fff;
            }
          }
          .rdrDayToday {
            background-color: $bgGrayDeep-color;
            border-radius: 50%;
            .rdrDayNumber {
              span {
                color: #000;
                border: 0 none;
                &:after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    .rdrDateRangePickerWrapper.PreviewArea {
      .rdrDefinedRangesWrapper {
        .rdrStaticRanges {
          .rdrStaticRange {
            border-bottom: 0 none;
            &:hover,
            &.rdrStaticRangeSelected {
              span {
                font-weight: 400;
                color: $navy-accent;
                background-color: #efeffe;
              }
            }
            .rdrStaticRangeLabel {
              padding: 9px 10px;
            }
          }
        }
      }
    }

    .rdrDateRangePickerWrapper.PreviewArea {
      .rdrCalendarWrapper {
        .rdrPprevButton,
        .rdrNextButton {
          padding: 0;
          background: transparent;
        }
        .rdrMonthsHorizontal {
          .rdrMonth {
            .rdrMonthName {
              font-size: 14px;
              font-weight: 500;
              color: $dark-color;
            }
          }
          .rdrDays {
            .rdrSelected,
            .rdrInRange {
              background-color: #efeffe;
              left: 0 !important;
              right: 0 !important;
            }
            .rdrStartEdge {
              right: 0 !important;
              left: 0 !important;
              border-top-left-radius: 1.042em;
              border-bottom-left-radius: 1.042em;
            }
            .rdrEndEdge {
              border-top-right-radius: 1.042em;
              border-bottom-right-radius: 1.042em;
            }
            .rdrDayStartOfMonth .rdrDayInPreview,
            .rdrDayStartOfMonth .rdrDayEndPreview,
            .rdrDayStartOfWeek .rdrDayInPreview,
            .rdrDayStartOfWeek .rdrDayEndPreview {
              border-radius: 0px;
            }

            .rdrDayEndOfWeek .rdrDayStartPreview,
            .rdrDayEndOfWeek .rdrDayInPreview,
            .rdrDayEndOfMonth .rdrDayStartPreview,
            .rdrDayEndOfMonth .rdrDayInPreview,
            .rdrDayEndOfMonth .rdrInRange,
            .rdrDayEndOfWeek .rdrInRange,
            .rdrDayEndOfWeek .rdrStartEdge .rdrDayStartOfMonth .rdrInRange,
            .rdrDayStartOfWeek .rdrInRange {
              border-radius: 0;
            }

            .rdrDayEndOfWeek .rdrDayStartPreview.rdrDayEndPreview,
            .rdrDayStartOfWeek .rdrDayStartPreview.rdrDayEndPreview,
            .rdrDayEndOfMonth .rdrDayStartPreview.rdrDayEndPreview,
            .rdrDayStartOfMonth .rdrDayStartPreview.rdrDayEndPreview {
              border-radius: 1.042em;
              color: #fff !important;
            }

            .rdrDayEndPreview,
            .rdrDayStartPreview,
            .rdrDayInPreview {
              border: 0 none;
              background-color: #efeffe;

              color: $dark-color !important;
              top: 0;
              bottom: 0;
            }

            .rdrStartEdge,
            .rdrEndEdge,
            .rdrDayStartPreview,
            .rdrDayEndPreview {
              background-color: $navy-accent;
            }

            .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
            .rdrDay:not(.rdrDayPassive) .rdrDayInPreview ~ .rdrDayNumber span,
            .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
              color: $dark-color !important;
            }
            .rdrDay:not(.rdrDayPassive).rdrDayHovered
              .rdrInRange
              ~ .rdrDayNumber
              span,
            .rdrDay:not(.rdrDayPassive).rdrDayHovered
              .rdrDayInPreview
              ~ .rdrDayNumber
              span,
            .rdrDay:not(.rdrDayPassive).rdrDayHovered
              .rdrSelected
              ~ .rdrDayNumber
              span {
              color: #fff !important;
            }
            .rdrDay:not(.rdrDayPassive) .rdrDayEndPreview ~ .rdrDayNumber span,
            .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
            .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span {
              color: #fff;
            }
            .rdrDay {
              margin-bottom: 3px;
              .rdrSelected,
              .rdrInRange,
              .rdrStartEdge,
              .rdrEndEdge {
                top: 0;
                bottom: 0;
              }
              .rdrDayNumber {
                z-index: 10;
              }
              &.rdrDayToday {
                background-color: $bgGrayDeep-color;
                color: #fff;
                border-radius: 50%;
                .rdrDayNumber {
                  span {
                    color: #000;
                    &:after {
                      display: none;
                    }
                  }
                }
              }
              &.rdrDayHovered {
                .rdrDayNumber span {
                  color: #fff !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.buttonGroup {
  border-top: 1px solid #eeeff2;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin: -4px -4px -15px;
  p {
    font-size: 13px;
    margin: 0 20px 0 0;
    font-weight: 500;
    color: $gray-color;
  }
  button {
    font-size: 12px;
    margin: 4px;
    height: 32px;
    padding: 0px 13.26px;
  }
}
