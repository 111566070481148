@use "../../../styles/abstracts" as *;

.content-wrapper {
  background: #ffffff;
  box-shadow: 0px 0px 2px #888;
  a {
    display: block;
    color: #888 !important;
    padding: 6px 14px;
  }
  a:hover {
    background: $bg-color-light;
    color: $soothe-color !important;
  }
  .dropdown-theme-2 {
    a:hover {
      background: $pink-color;
      color: $pink-color;
    }
  }
}
