.account-settings-card.ant-card {
  border-radius: 16px;
  box-shadow: 0px 5px 20px rgba(146, 153, 184, 0.031);

  .setting-card-title {
    div {
      margin-bottom: 0;
    }

    span {
      margin-left: 0;
      font-size: 13px;
      font-weight: 400;
    }
  }

  .ant-card-body {
    padding: 25px 0 25px !important;
  }

  .author-info {
    padding: 0 20px 20px;
    text-align: center;
    border-bottom: 1px solid $border-color-light;

    .info {
      background-color: transparent;
    }
  }

  .img-container {
    position: relative;
    max-width: 120px;
    height: 120px;
    margin: 0 auto 18px;

    .user-profile-img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
    }

    .ant-upload-select {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      span {
        display: inline-flex;
        height: 40px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 40px;
        background: $navy-accent;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $opposite-primary-color;
      }
    }
  }

  figcaption {
    .info {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 18px;
        margin-bottom: 4px;
      }

      p {
        margin-bottom: 0;
        color: $light-color;
      }
    }
  }

  .settings-list {
    padding: 20px 20px 0px 20px;

    li {
      a {
        display: flex;
        align-items: center;
        padding: 12px 20px;
        border-radius: 6px;
        color: $light-color;

        i,
        svg,
        img {
          margin-right: 13px;
        }

        &.active {
          font-weight: 500;
          color: $navy-accent;
          background: $highlight-color;
          border-radius: 4px;
        }
      }
    }
  }
}
.generic-card {
  border-radius: 8px !important;
  background-color: #ffffff !important;
  cursor: pointer;

  :global {
    .ant-card-body {
      padding: 24px !important
    }
  }
}
