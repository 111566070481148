.calendar-button {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  p {
    margin: 0;
    padding: 0 10px;
    font-size: 14px;
  }
}

.filter-btn {
  line-height: 0;
  height: 44px !important;
  border: 1px solid #e2e2e2 !important;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  color: #464a5b !important;

  &:focus,
  &:hover {
    border-color: #586b94 !important;
    background-color: #ffffff !important;
    span {
      color: #464a5b !important;
    }
  }
}

.down-arrow-icon {
  width: 10px !important;
  height: 10px !important;
  margin-left: 8px;
}
