.select_handler {
  width: 100%;
  padding: 10px !important;
  margin-top: -7px !important;
  border-radius: 4px;
}

.time-picker-popup {
  :global {
    .ant-picker-panel-container {
      border-radius: 6px;
    }
    .ant-picker-footer {
      display: none !important;
    }
  }
}

.modal-container {
  :global {
    .ant-modal {
      .ant-modal-content {
        border-radius: 16px;
        padding-top: 8px;

        .ant-modal-close {
          padding: 8px;

          > span > span {
            background-color: #f4f5f7;
            padding: 8px;
            border-radius: 50%;
            font-size: 13px;
          }
        }

        .ant-modal-header {
          > div {
            font-weight: 500;
          }
        }

        .ant-modal-body {
          button:first-child {
            // background-color: #f1f6ff;
            margin-right: 10px;
          }

          button:last-child {
            margin-right: -7px;
          }
        }
      }
    }
  }
}
