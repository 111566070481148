.table-container {
  :global {
    .ant-card {
      border-radius: 16px;
      border: none !important;
      box-shadow: 0px 5px 20px rgba(146, 153, 184, 0.031);
    }

    .ant-table {
      .ant-table-thead {
        th {
          border: 0;
          background-color: #fff;
          border-bottom: 1px solid #F1F2F6;

          &:first-child {
            border: 0;
            border-bottom: 1px solid #F1F2F6;
          }

          &:last-child {
            border: 0;
            border-bottom: 1px solid #F1F2F6;
          }
        }
      }

      .ant-table-tbody {
        .ant-table-row.table-row-light {
          background-color: #ffffff;
        }

        .ant-table-row.table-row-dark {
          background-color: #F6F6F6;
        }

        tr {
          &:hover {
            td {
              background-color: #F8F9FB
            }
          }

          td {
            border: 0;

            &:last-child {
              text-align: end;
            }
          }
        }
      }
    }
  }
}