@use "../../../styles/abstracts" as *;

.generic-input-style {
  border: 1px solid $light-gray;
  border-radius: 4px;
  padding: 14px 10px;
  font-size: 14px;
  line-height: 16px;
}

.generic-input-style::placeholder,
.generic-input-style input::placeholder,
.generic-input-style .anticon svg {
  color: $light-color;
}

.generic-checkbox-style .ant-checkbox-checked .ant-checkbox-inner {
  background-color: $navy-accent;
  border-color: $navy-accent;
}

.generic-checkbox-style:hover,
.generic-input-style:hover,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-checkbox-checked::after {
  border-color: $blue-gray;
}

.phone-number {
  max-width: 100%;
  border: 1px solid $light-gray;
  border-radius: 3px;
  width: 100%;
  padding: 14px 10px;

  &:hover {
    border-color: $blue-gray;
  }
  &:focus {
    outline: none;
    border-color: $blue-gray;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
}

:global {
  .iti {
    width: 100%;
  }
  .iti__flag-container {
    .iti__selected-flag {
      &:focus {
        outline: none;
      }
    }
  }
  .iti__country-list {
    padding: 10px 0;
    margin: 5px 0 0 -1px !important;
    border-radius: 4px;
    box-shadow: 0 5px 20px rgba(146, 153, 184, 0.1254901961) !important;
    border: none !important;

    li {
      padding: 10px;
    }
  }
}
