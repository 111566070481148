@import "../abstracts/mixins";

.form-wrapper {
  margin-top: 48px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-top: 32px;

  form {
    width: 650px;
  }
}

.accpet-invite-form {
  @include form-center;
  margin-top: 20px;
}

.sigin-form {
  @include form-center;
}

.change-password-form {
  padding: 0 24px;

  .input-message {
    font-size: 13px;
    color: $light-color;
    margin: -22px 0 24px;
  }
}

.edit-profile-form {
  padding: 0 24px;
}

.password-form {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__updtaed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h1 {
      margin: 50px 0 30px;
    }

    h3 {
      max-width: 80%;
      line-height: 25px;
    }
  }

  @media only screen and (max-width: 1200px) {
    width: 80%;
  }

  @media only screen and (max-width: 767px) {
    position: fixed;
    width: 80%;
  }
}
