.page-header {
  :global {
    padding: 29px 47px 29px 44px;

    nav.ant-breadcrumb li:first-child a {
      color: #0d79df;
    }

    .page-header-actions button.ant-btn-white svg {
      width: 12px;
      height: 12px;
      margin-left: 2px;
      color: #0d79df;
    }

    i+span,
    svg+span,
    img+span {
      margin-left: 6px;
    }
  }
}