@use "../../../styles/abstracts" as *;

.generic-cta-style,
.generic-cta-style:hover,
.generic-cta-style:active,
.generic-cta-style:focus {
  background-color: $navy-accent;
  color: $opposite-primary-color;
  font-weight: 400;
  padding: 16px;
  font-size: 14px;
  line-height: 16px;
  border-radius: 8px;
  height: 48px;
  margin: 34px 0px 12px;
}

.half-width {
  width: 50%;
}
