@use "../../../styles/abstracts" as *;

.calendar-wrapper {
  :global {
    .react-calendar {
      font-family: "Inter", sans-serif;
      width: 100%;
      border-radius: 10px;
      padding: 24px 35px;
      border: 0 none;
      background-color: #fff;
      box-shadow: 0 5px 20px $light-color 03;

      @media only screen and (max-width: 575px) {
        padding: 15px;
      }

      .react-calendar__viewContainer {
        .react-calendar__year-view__months__month {
          &:hover {
            background-color: transparent;
          }

          &.react-calendar__tile--hasActive {
            background-color: transparent;
            color: $primary-color;
          }
        }
      }
    }

    .react-calendar__navigation {
      margin-bottom: 0.5rem;

      button {
        &:hover,
        &:focus {
          background-color: #fff !important;
        }
      }

      .react-calendar__navigation__arrow {
        font-size: 20px;
        min-width: auto;
        color: $light-color;
      }

      .react-calendar__navigation__label {
        font-size: 16px;
        font-weight: 500;
        color: $dark-color;
      }
    }

    .react-calendar__month-view__weekdays {
      .react-calendar__month-view__weekdays__weekday {
        abbr {
          font-size: 13px;
          font-weight: 500;
          text-decoration: none;
          color: $light-color;

          @media only screen and (max-width: 1300px) {
            font-size: 11px;
          }
        }
      }
    }

    .react-calendar__month-view__days {
      .react-calendar__tile {
        max-width: 43px !important;
        padding: 1em 0.5em;

        &:hover {
          background-color: transparent;
          font-weight: 500;
          color: $dark-color;
        }

        &.react-calendar__month-view__days__day--neighboringMonth {
          abbr {
            color: $extra-light-color;
          }
        }

        abbr {
          font-size: 14px;
          color: $gray-color;
        }

        &.react-calendar__tile--now {
          padding: 0.929em 0.5em;
          background-color: transparent;
          border-radius: 50%;
          border: 1px solid $primary-color;
        }

        &.react-calendar__tile--active {
          border-radius: 50%;
          background-color: $primary-color;

          abbr {
            color: #fff;
          }
        }
      }
    }

    .calendar-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;

      @media only screen and (max-width: 877px) {
        flex-flow: column;
      }

      .calendar-header__left {
        display: flex;
        align-items: center;
        position: relative;

        .react-calendar__viewContainer {
          min-width: 320px;
          border-radius: 8px;
          padding: 15px !important;
          border: 0 none !important;
          box-shadow: rgba(82, 63, 105, 0.15) 0px 15px 40px 0px !important;

          @media only screen and (max-width: 479px) {
            min-width: 280px;
          }
        }

        @media only screen and (max-width: 877px) {
          margin-bottom: 20px;
        }

        @media only screen and (max-width: 479px) {
          flex-flow: column;
        }

        .btn-today {
          font-size: 13px;
          font-weight: 500;
          height: 34px;
          color: #5a5f7d;
          border-color: $border-color-light;

          @media only screen and (max-width: 479px) {
            margin-bottom: 15px;
          }
        }

        .year-select {
          .ant-select-selector {
            border: 0 none;

            .ant-select-selection-search {
              input {
                height: 35px;
              }
            }

            .ant-select-selection-item {
              font-size: 16px;
              font-weight: 500;
              color: $dark-color;
              padding-left: 10px !important;
            }
          }

          .ant-select-arrow {
            right: 18px;
          }
        }

        .react-calendar {
          margin-left: 30px;
          padding: 0;
          border: none;

          .react-calendar__navigation {
            display: flex;
            align-items: center;
            background-color: #fff;
            margin: 0;
            border: none;

            .react-calendar__navigation__label {
              padding: 0 20px;
              border: 0;
              background-color: #fff;
            }

            .react-calendar__navigation__arrow {
              border: 1px solid $border-color-light;
              border-radius: 5px;
              background-color: #fff;
              cursor: pointer;
              width: 34px;
              height: 34px;

              svg,
              img {
                width: 14px;
              }
            }
          }

          .react-calendar__viewContainer {
            position: absolute;
            background: #fff;
            z-index: 999;
            border: 1px solid #ddd;
            display: none;

            &.show {
              display: block;
            }
          }
        }

        .calender-head__navigation {
          display: flex;
          align-items: center;
          margin-left: 30px;

          @media only screen and (max-width: 479px) {
            margin-left: 0px;
          }

          .btn-navigate {
            width: 34px;
            height: 34px;
            padding: 0;

            i,
            svg {
              color: $light-color;
            }
          }

          .date-label {
            display: inline-block;
            margin: 0 20px;
            font-size: 16px;
            font-weight: 500;
            color: $dark-color;
          }
        }
      }

      .calendar-header__right {
        display: flex;

        @media only screen and (max-width: 479px) {
          flex-flow: column;
          align-items: center;
        }

        svg {
          width: 14px;
        }

        ul {
          li {
            display: inline-block;

            &:first-child {
              // border-left: 1px solid $border-color-light;
            }

            &:not(:first-child) {
              a {
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
              }
            }

            &:not(:last-child) {
              a {
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
              }
            }

            &.active {
              color: #586b94;
              border-color: $border-color-light;
              background-color: #f1f6ff;
              border-radius: 4px;
            }

            &.deactivate {
              color: #586b94;
              border-color: $border-color-light;
              background-color: #fff;
            }

            a {
              font-size: 14px;
              font-weight: 500;
              color: $soothe-color;
              border: 2px solid $border-color-light;
              display: block;
              border-radius: 4px;
              padding: 6px 13.24px;
            }

            &:not(:first-child) {
              a {
                border-left: 0px;
              }
            }
          }
        }

        .schedule-list {
          margin-left: 20px;
          display: flex;
          align-items: center;
          color: $light-color;

          @media only screen and (max-width: 479px) {
            margin: 15px 0 0 0;
          }

          svg,
          img,
          i {
            margin-right: 6px;
            color: $primary-color;
          }

          &.active {
            color: $primary-color;
          }
        }
      }
    }

    .table-event {
      border: 1px solid $border-color-light;

      &.table-responsive {
        @media only screen and (max-width: 1280px) {
          display: block;
          width: 100%;
          overflow-x: auto;
        }
      }

      tr {
        th,
        td {
          padding: 16px 0;
          border-bottom: 1px solid $border-color-light;

          &:first-child {
            min-width: 75px;
            padding: 16px 18px 16px 18px;
            border-right: 1px solid $border-color-light;
          }
        }
      }

      thead {
        th {
          padding: 0 22px;
          background-color: $bg-color-light;

          p {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 0;
          }
        }
      }

      tbody {
        td {
          padding: 0 2px;
          font-size: 12px;
          min-width: 140px;
          color: $light-color;

          .currentTime {
            width: calc(100% + 20px);
            height: 1px;
            display: block;
            position: relative;
            left: -10px;
            z-index: 222;

            &:after {
              position: absolute;
              left: 0;
              top: -6px;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              content: "";
              background-color: $secondary-color;
            }
          }

          .day-event-item {
            width: 75%;
            margin: auto;
            display: block;
            border-radius: 3px 6px 6px 3px;
            padding: 6px 12px;
            position: relative;

            &:after {
              position: absolute;
              left: 0;
              bottom: 0;
              width: 2.5px;
              height: 100%;
              border-radius: 6px 0 0 6px;
              content: "";
            }

            &.primary {
              background-color: #efeffe;
              color: $primary-color;

              &:hover {
                color: $primary-color;
              }

              &:after {
                background-color: $primary-color;
              }
            }

            &.cancelled {
              background-color: #ffffff;
              color: #afafaf;
              border: 2px solid #afafaf;
              border-radius: 5px;

              &:hover {
                color: #afafaf;
              }

              // &:after{
              //     background-color: #AFAFAF;
              // }

              p:not(:nth-child(1)),
              span:not(:nth-child(1)) {
                text-decoration: line-through;
                color: #afafaf;
                font-weight: normal;
              }
            }

            &.created,
            &.completed {
              background-color: #e8faf4;
              color: $success-color;

              &:after {
                background-color: $success-color;
              }
            }

            &.needs-attention {
              background-color: #ffefed;
              color: #ff4d4f;
              // border: 2px solid #FF4D4F;
              border-radius: 5px;

              &:hover {
                color: #ff4d4f;
              }

              &:after {
                background-color: #ff4d4f;
              }

              p,
              span:not(:nth-child(1)) {
                color: #ff4d4f;
                font-weight: normal;
              }
            }

            &.is-training {
              background-color: #ffc043;
              color: #bc8b2c;
              border-radius: 5px;

              &:hover {
                color: #bc8b2c;
              }

              &:after {
                background-color: #bc8b2c;
              }

              p,
              span:not(:nth-child(1)) {
                color: #000;
                font-weight: 400;
              }
            }

            &.pending {
              background-color: #fff3e6;
              color: $warning-color;

              &:after {
                background-color: $warning-color;
              }
            }

            &.filled {
              background-color: #f1f6ff;
              color: $info-color;

              &:after {
                background-color: $info-color;
              }
            }

            &.partially-filled {
              background-color: #f1f6ff;
              color: #88afff;

              &:after {
                background-color: #88afff;
              }
            }

            .event-title {
              display: block;
              font-size: 13px;
              font-weight: 500;
              margin-top: 6px;
              color: #000000;
            }
          }
        }
      }
    }

    .emptyData {
      min-height: 110px;
      font-size: 18px;
      align-items: center;
      justify-content: center;
      color: $light-color;
    }

    .event-week {
      tr {
        th,
        td {
          &:not(:last-child) {
            border-right: 1px solid $border-color-light;
          }
        }
      }

      thead {
        tr {
          th {
            text-align: center;

            .week-dayName {
              font-size: 13px;
              font-weight: 400;
              margin-bottom: 6px;
              margin-top: 6px;
              color: $light-color;
            }

            .week-date {
              display: inline-block;
              line-height: 1.45;
              border-radius: 50px;
              font-size: 13px;
              padding: 4px 12px;
              margin-bottom: 8px;

              &.primary {
                padding: 4px 12px;
              }
            }
          }
        }
      }

      tbody {
        .ant-dropdown-trigger {
          display: inline-block;
        }
      }
    }

    .schedule-event {
      tr {
        &:hover {
          box-shadow: 0 15px 40px $light-color 15;

          td {
            border-color: #fff;
          }
        }

        td {
          padding: 10px 20px !important;
          vertical-align: top;
          font-size: 14px;

          &:first-child {
            border-right: 0 none;
          }

          &.schedule-time {
            direction: ltr;
            min-width: 100px;
            width: 130px;
          }

          .ant-row {
            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }

          .schedule-date {
            font-weight: 400;
            font-size: 14px;
            color: $dark-color;
          }

          .schedule-date-name {
            margin-left: 10px;
          }

          .event-title {
            font-weight: 400;
            font-size: 14px;
            color: #000000;

            @media only screen and (max-width: 575px) {
              display: inline-block;
              margin-top: 6px;
            }
          }

          .schedule-time {
            font-size: 14px;
            color: $dark-color;
          }
        }
      }
    }

    .events {
      li {
        position: relative;

        .ant-badge {
          .ant-badge-status-dot {
            padding: 4.5px;
          }
        }

        a {
          position: absolute;
          top: 0;
          left: 0;
          padding: 5px 12px;
          z-index: 1;
          border-radius: 4px;
          font-size: 13px;

          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
}
