.title-16 {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #000000;
  margin-bottom: 10px;
}
.title-14 {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #000000;
  margin-bottom: 10px;
}
.subtitle-14 {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #757575;
}
.ptb-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
