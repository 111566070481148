@use "../../../styles/abstracts" as *;

.main-cta-link,
.main-cta-link:focus,
.main-cta-link:hover,
.main-cta-link:active,
.main-cta-link:visited {
  color: $cactus-flower;
  text-decoration: none;
}

.copy-color {
  color: $navy-blue;
}

.text-align-right {
  text-align: right;
}

.padding-bottom-16 {
  padding-bottom: 16px;
}