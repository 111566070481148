.modal-style {
  :global {
    .ant-modal {
      .ant-modal-content {
        border-radius: 16px;
        padding-top: 8px;
        .ant-modal-header {
          border: none;
          > div {
            font-weight: 500;
            font-size: 18px;
          }
        }
        .ant-modal-body {
          padding-top: 0;
          font-weight: 400;
          font-size: 16px;
        }
      }
    }
  }
}
