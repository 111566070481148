.slider-buttons {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;

  &:first-child {
    margin-left: -15px;
  }

  &:last-child {
    margin-right: -15px;
  }
}

.calendar-button {
  border: 1px solid #e2e2e2 !important;
}

.tasks-list__container {
  :global {
    table {
      tr {
        td {
          border: 0;
          padding: 8px 0;
        }
      }
    }
  }

  .tasks-list__action {
    margin-top: 10px;

    button {
      width: 100%;
      text-align: center;
      color: #39455e;
      background-color: #f1f6ff;
    }

    justify-content: flex-start;
    font-size: 12px;
    font-weight: 500;
  }
}

.link {
  font-weight: 500;
  font-size: 16px;
  color: #586b94;
  font-weight: 500;

  &:hover {
    color: #8497c0;
  }
}

.tasks-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    margin-right: 10px;
  }
}

.task {
  &.active {
    color: red;
  }

  text-decoration: line-through;
}

.scrollbox {
  max-height: 550px;
  overflow-y: scroll;
  width: 102%;
}

.activities-list {
  width: 99%;

  .activity-block {
    &:not(:last-child) {
      margin-bottom: 8px;
      padding-bottom: 8px;
    }
  }

  .activity-date {
    height: 100%;
    min-height: 70px;
    border-radius: 10px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background: #f2f4ff;
    font-weight: 500;
    font-size: 18px;

    @media only screen and (max-width: 575px) {
      height: auto;
      padding: 30px 0px;
      margin-bottom: 10px;
    }

    div {
      font-size: 17px;
      margin-bottom: 6px !important;
    }

    span {
      font-weight: 400;
      margin-bottom: 0px;
    }

    .activity-month {
      color: lightgoldenrodyellow;
    }
  }

  .activity-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 10px 0;
    padding-left: 20px;

    @media only screen and (max-width: 800px) {
      flex-wrap: wrap;
      padding-left: 0;

      a {
        width: 100%;
      }
    }
  }

  .activity-title {
    width: 85%;
    font-size: 14px;
    font-weight: 400;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;

    a {
      font-weight: 500;
      color: #5f637a;
      margin-right: 10px;
      border: 1px solid #5f637a;
      padding: 4px 8px;
      border-radius: 4px;
      max-width: 40%;
      width: fit-content;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .appt-number {
      color: #898da5;
      text-decoration: underline;
      font-weight: 400;
      font-size: 14px;
      cursor: pointer;
    }

    @media only screen and (max-width: 800px) {
      padding: 0 8px;
      width: 100%;
      display: block;

      span {
        display: inline-block;
        vertical-align: middle;
      }

      a {
        display: inline-block;
        max-width: 100%;
        width: 100%;
      }
    }
  }

  .activity-time {
    padding: 4px 8px;
    text-align: end;
    color: #757575;
    width: fit-content;

    @media only screen and (max-width: 800px) {
      width: 100%;
      text-align: start;
      padding: 0 8px;
    }
  }
}

.calendar {
  :global {
    .rdrDefinedRangesWrapper {
      display: none;
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent; /* Track color */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #c0c0c0; /* Thumb color */
  border-radius: 10px; /* Rounded corners */
  border: 2px solid transparent; /* Border around the thumb */
  transition: background-color 0.3s, border-color 0.3s; /* Smooth transition on hover */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a0; /* Hovered thumb color */
  border-color: #808080; /* Border color on hover */
}

.responsive-flex {
  display: flex;

  @media only screen and (max-width: 1000px) {
    display: block;
  }
}