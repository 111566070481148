@use "../../../../../../styles/abstracts/" as *;

.wrapper {
  width: 100%;
  :global {
    .step-action-wrap {
      display: flex;
      justify-content: center;
      .step-action-inner {
        width: 580px;
        padding: 0 25px;
        @media only screen and (max-width: 575px) {
          width: 100%;
          padding: 0;
        }
      }
    }
    .ant-form-item {
      margin: 0;
    }
    .steps-action {
      margin-top: 1rem;
      width: 100%;
      float: right;
      display: flex;
      @media only screen and (max-width: 991px) {
        margin-top: 25px;
      }
      @media only screen and (max-width: 379px) {
        flex-flow: column;
      }
      .create-mode-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      .cancel-button {
        margin-left: 13px;
      }
      .ant-switch-checked {
        background-color: #586b94;
      }
      .button {
        display: flex;
        align-items: center;
        height: 44px;
        padding: 0 20px;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
        @media only screen and (max-width: 379px) {
          justify-content: center;
        }
        &.ant-btn-light {
          border: 1px solid $border-color-light;
        }
        &.btn-next {
          svg {
            margin-left: 10px;
          }
        }
        &.btn-prev {
          svg {
            margin-right: 10px;
          }
        }
      }
      .ant-btn-primary {
        border-color: #f58266;
        background-color: #f58266;
      }
      .ant-btn-secondary {
        border-color: #f1f6ff;
        background-color: #f1f6ff;
        color: #39455e;
      }
    }

    .save-action {
      display: flex;
      @media only screen and (max-width: 467px) {
        flex-direction: column;
        flex-direction: column-reverse;
      }
    }
    .submit-button {
      @media only screen and (max-width: 467px) {
        margin-left: 0px;
      }
    }
  }
}
