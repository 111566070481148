@use "../../../styles/abstracts" as *;

.main-dropdown {
  font-size: 14px;
  width: 375px;

  :global {
    .ant-select-selection-search {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.main-dropdown-container {
  :global {
    .ant-select .ant-select-selector {
      border-radius: 4px;
      height: 42px !important;
      align-items: center;
    }

    .ant-select-selection-placeholder {
      color: $navy-blue;
      font-weight: 400;
      padding-left: 0px;
      font-size: 14px;
    }
  }
}

.modal-dropdown {
  border-radius: 4px;
  line-height: 44px;
  width: 100%;

  :global {
    .ant-select-selector {
      border-radius: 4px !important;
      border: 1px solid $light-gray !important;

      &:hover {
        border-color: #ADB4D2 !important
      }
    }

    .ant-select-selection-placeholder {
      color: $light-color;
      font-weight: 400;
      padding-left: 0px;
      font-size: 14px;
    }

    .ant-select-selection-item {
      border: 4px;
      padding: 4px 8px;
      height: 100%;
    }

    .ant-select-arrow {
      width: 20px;
      color: #323232 !important;
    }

    .ant-select-clear {
      width: 20px;
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 405px) {
  .main-dropdown-container {
    :global {
      .ant-select .ant-select-selector {
        width: 75vw;
      }
    }
  }
}

@media screen and (max-width: 405px) {
  .main-dropdown {
    width: 77vw;
  }
}