.appointment-select {
  width: 100%;

  :global {
    .ant-select-selector {
      border-radius: 5px !important;
      border-color: #e2e2e2 !important;
      padding: 10px !important;
      min-height: 55px !important;
    }

    .ant-select-selection-placeholder {
      color: #9299b8;
    }

    .ant-select-arrow {
      width: 20px;
      color: #323232 !important;
    }
    .ant-select-clear {
      width: 20px;
      font-size: 20px;
    }
  }
}

.form-group {
  width: 100%;

  :global {
    .ant-space-item {
      width: 100%;
    }
    .ant-input::placeholder {
      color: #9299b8;
    }
  }
}

.taps-container {
  display: flex;
  justify-content: center;
  width: 100%;

  :global {
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: #586b94 !important;
      border-color: #586b94;
      color: #ffffff !important;
    }
    .ant-radio-button-wrapper {
      height: 2.0625rem !important;
      line-height: 46px;
      padding: 0 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media only screen and (max-width: 1024px) {
        padding: 0 16px !important;
      }
      @media only screen and (max-width: 700px) {
        padding: 0 10px !important;
      }
      @media only screen and (max-width: 379px) {
        height: 40px !important;
        line-height: 38px !important;
        font-size: 12px;
        padding: 0 6px;
      }
      color: #586b94;
      @media only screen and (max-width: 670px) {
        width: 100% !important;
        margin-bottom: 1%;
      }
    }
    .ant-radio-button-wrapper:not(:first-child):before {
      width: 0;
    }
  }
}

.tap {
  width: 18%;
  border-radius: 5px !important;
}
.session-tap {
  font-weight: 500;
  font-size: 15px !important;
  &:first-child {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
  }
  &:last-child {
    border-bottom-right-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
}

.discount-tap {
  width: 100%;
  margin: 0px 10px;
  border-radius: 5px !important;
}
.tip-input {
  margin: auto;
  width: 50%;
}

.form-item-container {
  padding: 0;
  margin: 0;
  div > div {
    margin: 0px !important;
    margin-bottom: 5px !important;
  }
}
