@use "../../styles/abstracts" as *;

.H0 {
  font-size: 60px;
  line-height: 69px;
  margin-bottom: 24px;
  color: $primary-color
}
.H1 {
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 24px;
  color: $primary-color
}
.H2 {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 24px;
  color: $primary-color
}
.H3 {
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 24px;
  color: $primary-color
}
.H4 {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 24px;
  color: $primary-color
}
.H5 {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 24px;
  color: $primary-color
}

.H6 {
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 24px;
  color: $primary-color
}
.H7 {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 24px;
  color: $primary-color
}
