@use "../../../../styles/abstracts" as *;
.wrapper {
  :global {
    .card-wrapper {
      width: 100%;

      .ant-btn {
        box-shadow: none;
      }
      .ant-card-bordered {
        border: none;
      }
      .header {
        margin-top: -8px;
        .header__text {
          font-size: 15px;
          font-weight: 500;
        }
        .btn-toggle {
          font-size: 13px;
          background: none;
          border: none;
          cursor: pointer;
          color: $info-color;
        }
      }

      .ant-card {
        box-shadow: none !important;
      }

      .ant-card .ant-card-body {
        padding: 0 !important;
        margin-bottom: 0 !important;
      }
    }
  }
}
