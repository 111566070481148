.cards-container {
  :global {
    .ant-card {
      background: #F8F9FB;
      border: 1px solid #E2E2E2;
      border-radius: 16px;

      .ant-card-head {
        border: none;
        color: #9299B8;
        font-weight: 400;
        font-size: 14px;
        padding-top: 8px;
        .ant-card-head-title {
          padding-bottom: 0px !important;
        }
        .ant-card-extra {
          padding-bottom: 0px !important;
        }
      }
    }
  }
}

.view-more{
  color: #586B94;
  font-weight: 400;
  font-size: 14px;

  &:hover {
    color: #8497c0
  }
}

.slider-buttons {
  background: #FFFFFF;
  border: 1px solid #E2E2E2;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.activity-container {
  background: #FFFFFF;
  border-radius: 16px;
  box-shadow: 0px 5px 20px rgba(146, 153, 184, 0.031);
  padding: 20px;
  .notification-header {
    background: #FFFFFF;
  }
}
