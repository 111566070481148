.files-container {
  background: #FFFFFF;
  border-radius: 16px;
  box-shadow: 0px 5px 20px rgba(146, 153, 184, 0.031);
  margin-top: -15px;

  :global {
    .ant-card {
      .ant-card-body {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }
}

.btn-container {
  text-align: right;
}

.file-btn {
  color: #39455E !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding: 0 15px;

  &:hover,
  &:focus,
  &:active {
    color: #39455E;
    background-color: #F1F6FF;
  }
}