.delete-modal {
  width: 420px !important;

  &__content {
    display: flex;
    color: $navy-blue;
    flex-direction: column;
    align-items: center;

    img {
      margin-bottom: 33px;
      width: 94px;
    }
  }

  &__buttons:first-child {
    border: none;
    background-color: $cactus-flower;
    color: white;
  }

  &__buttons:last-child {
    border: none;
    margin-left: 10px;
    background-color: $blue-subtle;
    color: $dark-blue;
  }
}

.invite-team-member-modal {
  .ant-form-item-control-input {
    margin-top: 10px;
    min-height: auto;
  }
}

.no-lines-modal {
  .ant-modal-header {
    border-bottom: 0px;
    padding-bottom: 8px;

    .ant-modal-title {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .ant-modal-body {
    font-size: 16px;
    line-height: 24px;
    padding: 8px 24px;
  }

  .ant-modal-footer {
    border-top: 0px;
  }

  strong {
    font-weight: 500;
    line-height: 20px;
  }
}
.ant-modal-footer:has(.view-appt-footer) {
  background-color: #fff;
  padding: 0px;

  div.view-appt-footer {
    border-top: 1px solid #f1f2f6;
    text-align: end;
    margin-bottom: -5px;
    background-color: #fff;
    padding: 16px 24px 24px;
    .flt-start {
      float: inline-start;
    }
  }
  .flt-start {
    float: inline-start;
  }
}