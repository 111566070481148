@use "../../../../styles/abstracts" as *;

.appointment-select {
  width: 100%;

  :global {
    .ant-select-selector {
      border-radius: 5px !important;
      border-color: #e2e2e2 !important;
      padding: 10px !important;
      min-height: 55px !important;
    }

    .ant-select-selection-placeholder {
      color: #9299b8;
    }

    .ant-select-arrow {
      width: 20px;
      color: #323232 !important;
    }
    .ant-select-clear {
      width: 20px;
      font-size: 20px;
    }
  }
}

.form-group {
  width: 100%;

  :global {
    .ant-space-item {
      width: 100%;
    }
    .ant-input::placeholder {
      color: #9299b8;
    }
  }
}

.taps-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  row-gap: 0.6rem;
  @media only screen and (max-width: 670px) {
    flex-direction: column;
    width: 100% !important;
  }
  :global {
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: #586b94 !important;
      color: #ffffff !important;
    }
    .ant-radio-button-wrapper {
      border-left: 1px solid #d9d9d9;
      display: flex;
      justify-content: center;
      border-radius: 2px 0 0 2px;
      height: 48px;
      line-height: 46px;
      padding: 0px 25.25px;
      @media only screen and (max-width: 670px) {
        width: 100% !important;
        margin-bottom: 1%;
      }
    }
    .ant-radio-button-wrapper:not(:first-child):before {
      width: 0;
    }
  }
}

.tap {
  width: 18%;
  border-radius: 5px !important;
}
.session-tap {
  border-radius: 5px !important;
}

.discount-tap {
  width: 100%;
  border-radius: 5px !important;

  &:hover {
    color: #586b94;
  }
}
.tip-input {
  margin: auto;
  width: 50%;
}

.wrapper {
  :global {
    .card-wrapper {
      width: 100%;

      .ant-btn {
        box-shadow: none;
      }
      .ant-card-bordered {
        border: none;
      }
      .header {
        margin-top: -8px;
        .header__text {
          font-size: 15px;
          font-weight: 500;
        }
        .btn-toggle {
          font-size: 13px;
          background: none;
          border: none;
          cursor: pointer;
          color: $info-color;
        }
      }

      .ant-card {
        box-shadow: none !important;
      }

      .ant-card .ant-card-body {
        padding: 0 !important;
        margin-bottom: 0 !important;
      }
    }
  }
}

.title {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1rem;
  margin: 2rem 0 1rem;
}
