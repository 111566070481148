body {
  font-family: "Poppins", sans-serif;
  color: $primary-color;
  font-size: 14px;
  line-height: 16px;
}

body,
html {
  font-family: "Poppins", sans-serif;
}

.link-selected {
  color: #586b94 !important;
  background: #f0f5ff !important;
}

.ant-form-item-explain-error {
  padding-top: 8px;
  padding-bottom: 22px;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  z-index: 0;
}
