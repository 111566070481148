.steps {
  :global {
    .ant-steps {
      width: 100%;
      margin-left: -20px;
      margin-bottom: 42px;

      @media only screen and (max-width: 766px) {
        margin-left: -40px;
      }

      // @media only screen and (max-width: 650px) {
      // 	margin-left: -42px;
      // }
      @media only screen and (max-width: 575px) {
        margin-left: 0px;
      }
    }

    .ant-steps-item {
      white-space: nowrap;

      @media only screen and (max-width: 766px) {
        white-space: normal;
      }
    }

    .ant-steps-item-tail {
      position: absolute;
      top: 19px;
      left: 9px;

      &:after {
        right: 100%;
        color: #333;
        background-image: url("../../../static/img/progress.svg");
        width: 100%;
        height: 6px;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent !important;
      }
    }

    .ant-steps-item-process {
      .ant-steps-item-container {
        .ant-steps-item-icon {
          span {
            color: #586b94;
            font-weight: 500;
            font-size: 16px;
          }

          background-color: #f1f6ff;
        }
      }
    }

    .ant-steps-item-wait {
      .ant-steps-item-icon {
        box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);

        .ant-steps-icon {
          color: #272b41;
        }
      }
    }

    .ant-steps-item-icon {
      font-family: "Inter", sans-serif;
      width: 50px;
      height: 50px;
      line-height: 50px;
      border: 0 none;
      box-shadow: 10px 0 20px #9299b815;

      .ant-steps-icon {
        font-family: Helvetica;
        font-size: 16px;
        font-weight: 500;
        color: #272b41;
        line-height: 18px;
      }
    }

    .ant-steps-item-content {
      white-space: normal;

      .ant-steps-item-title {
        font-weight: 500;
        line-height: 16px;
        margin-left: 12px;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}