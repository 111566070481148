.appointment-card {
  border: 0.5px solid #E2E2E2 !important;
  border-radius: 8px !important;
  background-color: #ffffff !important;
  margin: 0 8px;
  background-size: cover;
  cursor: pointer;

  :global {
    .ant-card-body {
      padding: 16px !important
    }
  }
}

.appointment-card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}

.appointment-card__subtitle {
  color: #545454;
  font-weight: 400;
  font-size: 14px;
  margin-top: -22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 100px;
}

.one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 140px;
}

.appointment-card__body {
  margin-top: 10px;
  div {
    div:nth-child(2) {
      margin-left: 10px;
      font-weight: 400;
      font-size: 14px;
    }
  }
}
