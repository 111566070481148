@use "../../../../styles/abstracts" as *;

.appointment-select {
  width: 100%;

  :global {
    .ant-select-selector {
      border-radius: 5px !important;
      border-color: #e2e2e2 !important;
      padding: 10px !important;
      min-height: 55px !important;
    }

    .ant-select-selection-placeholder {
      color: #9299b8;
    }

    .ant-select-arrow {
      width: 20px;
      color: #323232 !important;
    }
    .ant-select-clear {
      width: 20px;
      font-size: 20px;
    }
  }
}

.form-group {
  width: 100%;

  :global {
    .ant-space-item {
      width: 100%;
    }
    .ant-input::placeholder {
      color: #9299b8;
    }
  }
}

.taps-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media only screen and (max-width: 670px) {
    flex-direction: column;
    width: 100% !important;
  }
  :global {
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: #586b94 !important;
      color: #ffffff !important;
    }
    .ant-radio-button-wrapper {
      border-left: 1px solid #d9d9d9;
      display: flex;
      justify-content: center;
      border-radius: 2px 0 0 2px;
      @media only screen and (max-width: 670px) {
        width: 100% !important;
        margin-bottom: 1%;
      }
    }
    .ant-radio-button-wrapper:not(:first-child):before {
      width: 0;
    }
  }
}

.tap {
  width: 18%;
  border-radius: 5px !important;
}
.session-tap {
  border-radius: 5px !important;
}

.discount-tap {
  width: 100%;
  margin: 0px 10px;
  border-radius: 5px !important;
}
.tip-input {
  margin: auto;
  width: 50%;
}

.date-picker {
  width: 100%;

  :global {
    .ant-picker-input {
      padding: 10px;
    }
  }
}

.custom-date-picker {
  :global {
    .ant-picker-input {
      padding: 10px;
    }
  }
}

.appointment-select {
  width: 100%;

  :global {
    .ant-select-selector {
      border-radius: 5px !important;
      border-color: #e2e2e2 !important;
      padding: 10px !important;
      min-height: 55px !important;
    }

    .ant-select-selection-placeholder {
      color: #9299b8;
    }

    .ant-select-arrow {
      width: 20px;
      color: #323232 !important;
    }
    .ant-select-clear {
      width: 20px;
      font-size: 20px;
    }
  }
}

.form-group {
  width: 100%;

  :global {
    .ant-space-item {
      width: 100%;
    }
    .ant-input::placeholder {
      color: #9299b8;
    }
  }
}

.date-time-container {
  @media only screen and (max-width: 475px) {
    flex-direction: column;
  }
}

.date-container {
  @media only screen and (max-width: 475px) {
    width: 100% !important;
  }
}
.custom-Date-container {
  margin-bottom: 0px !important ;
  @media only screen and (max-width: 475px) {
    width: 100% !important;
  }
}

.date-picker-input {
  @media only screen and (max-width: 475px) {
    width: 100%;
  }
}

.form-group {
  width: 100%;

  :global {
    .ant-space-item {
      width: 100%;
    }
    .ant-input::placeholder {
      color: #9299b8;
    }
  }
}

.taps-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  @media only screen and (max-width: 670px) {
    flex-direction: column;
    width: 100% !important;
  }
  :global {
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: #586b94 !important;
      color: #ffffff !important;
    }
    .ant-radio-button-wrapper {
      border-left: 1px solid #d9d9d9;
      border-radius: 2px 0 0 2px;
      @media only screen and (max-width: 670px) {
        width: 100% !important;
        margin-bottom: 1%;
      }
    }
    .ant-radio-button-wrapper:not(:first-child):before {
      width: 0;
    }
  }
}

.tap {
  width: 18%;
  border-radius: 5px !important;
  text-align: center;
}
.day-tap {
  border-radius: 5px !important;
  text-align: center;
}
.session-tap {
  width: 32%;
  border-radius: 5px !important;
  text-align: center;
}

.tip-input {
  margin: auto;
  width: 50%;
}

.custom-repeat-select {
  width: 20%;

  :global {
    .ant-select-selector {
      border-radius: 5px !important;
      border-color: #e2e2e2 !important;
      padding: 10px !important;
      min-height: 50px !important;
    }

    .ant-select-selection-placeholder {
      color: #9299b8;
    }

    .ant-select-arrow {
      width: 20px;
      color: #323232 !important;
    }
    .ant-select-clear {
      width: 20px;
      font-size: 20px;
    }
  }
}

.repeats-every {
  width: 50%;
}

.checkbox-group {
  :global {
    .ant-checkbox-group {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      width: 100%;
      flex-wrap: no-wrap;

      @media only screen and (max-width: 670px) {
        flex-direction: column;
        width: 100% !important;
      }

      .ant-checkbox-wrapper-checked {
        background-color: #586b94 !important;
        color: #ffffff !important;
      }

      > label.ant-checkbox-wrapper {
        font-size: 14px;
        align-items: center;
        width: 100%;
        border-radius: 5px !important;
        background: #fff;
        border-color: #d9d9d9;
        padding: 0 15px;
        height: 48px;
        border: 1px solid rgb(217, 217, 217);

        @media only screen and (max-width: 670px) {
          width: 100% !important;
          margin-bottom: 1%;
        }

        > span:first-child {
          display: none;
        }

        > span:last-child {
          padding: 0;
        }

        & > span {
          margin: auto;
        }
      }

      > label.ant-checkbox-wrapper-checked {
        border: 1px solid #1890ff;
      }

      > label.ant-checkbox-wrapper:last-child {
        border-right-width: 1px;
      }
    }
  }
}
.wrapper {
  :global {
    .card-wrapper {
      width: 100%;

      .ant-btn {
        box-shadow: none;
      }
      .ant-card-bordered {
        border: none;
      }
      .header {
        margin-top: -8px;
        .header__text {
          font-size: 15px;
          font-weight: 500;
        }
        .btn-toggle {
          font-size: 13px;
          background: none;
          border: none;
          cursor: pointer;
          color: $info-color;
        }
      }

      .ant-card {
        box-shadow: none !important;
      }

      .ant-card .ant-card-body {
        padding: 0 !important;
        margin-bottom: 0 !important;
      }
    }
  }
}
