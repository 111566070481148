@use "../../../styles/abstracts" as *;

.modal {
  :global {
    .ant-modal {
      .ant-modal-content {
        border-radius: 16px;
        padding-top: 8px;

        .ant-modal-close {
          padding: 8px;

          >span>span {
            background-color: #f4f5f7;
            padding: 8px;
            border-radius: 50%;
            font-size: 13px;
          }
        }

        .ant-modal-header {
          border-bottom: none;
          >div {
            font-weight: 500;
          }
        }

        .ant-modal-body {
          button:first-child {
            // background-color: #f1f6ff;
            margin-right: 10px;
          }

          button:last-child {
            background-color: #c11500;
            margin-right: -7px;

            &:hover {
              background-color: #8e1404;
            }
          }
        }

        .ant-modal-footer {
          border-top: none;
        }
      }
    }
  }
}

.navy-modal {
  :global {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-body {
          button:last-child {
            background-color: $navy-accent;
            margin-right: -7px;

            &:hover {
              background-color: $navy-accent;
            }
          }
        }
      }
    }
  }
}
