.card-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  gap: 1rem;
}

.business__name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;

  div {
    font-weight: 400;
    font-size: 20px;
    color: #000000;
  }
}

.business__created-at {
  font-weight: 400;
  font-size: 14px;
  color: #545454;
}

.tabs-link {
  color: #586b94;
  font-weight: 500;

  &:hover {
    color: #8497c0;
  }
}

.tabs {
  :global {
    .ant-tabs-nav {
      color: #757575;
      font-weight: 500;

      &:hover,
      &:active {
        color: #757575;
      }

      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
            background: #586b94;
            height: 2px;
          }
          .ant-tabs-tab {
            &:hover {
              color: #586b94;
            }

            &.ant-tabs-tab-active {
              .ant-tabs-tab-btn {
                color: #586b94;
              }
            }
          }
        }
      }

      &:before {
        border-bottom: 2px solid #f0f0f0;
      }
    }

    .ant-tabs-content-holder {
      margin-top: 20px;
    }
  }
}

.link-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
