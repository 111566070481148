@import "../../../styles/abstracts/variables";

.title {
  color: $blue-gray;
}
.margin-bottom-40 {
  margin-bottom: 40px;
}

.error-img {
  @media only screen and (max-width: 400px) {
    width: 80%;
  }
}

.text-align-center {
  text-align: center;
}

.full-height {
  height: 100%;
}
