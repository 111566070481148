.ant-radio-vertical .ant-radio-wrapper {
  display: block;
}

.ant-radio-vertical .ant-radio-wrapper:not(:last-child) {
  margin-bottom: 5px;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $navy-accent !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: $navy-accent;
}

.ant-radio:hover .ant-radio-inner {
  border-color: $navy-accent;
}
