@mixin form-center {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 1200px) {
    width: 80%;
  }

  @media only screen and (max-width: 767px) {
    position: fixed;
    width: 80%;
  }
}
