.H1 {
  color: #272b41;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
}

.H2 {
  color: #272b41;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  margin-bottom: 42px;
}

.H3 {
  color: #272b41;
  font-size: 22px;
  font-weight: 400;
  line-height: 27px;
}

.H4 {
  color: #272b41;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

.H5 {
  color: #272b41;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}

.H6 {
  color: #272b41;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
