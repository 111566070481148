.modal-card {
  color: #272b41;
  border: none;
  border-radius: 8px !important;
}

.card-content {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  width: 100%;
  p {
    margin: 0;
    padding: 0;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.tip-amount-title {
  margin-top: 20px;
  margin-bottom: 1%;
}
.tip-amount-button {
  width: 48px;
  height: 48px;
}
.tip-amount {
  margin-left: 16px;
  width: 80%;
}

.left-button {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.right-button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.panel-container {
  :global {
    .ant-collapse
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-header-text {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(255, 255, 255, 0.7);
}

.tip-selector {
  :global {
    .ant-select-single .ant-select-selector .ant-select-selection-item {
      line-height: 46px !important;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: fit-content;
      width: 60px;
      border: 1px solid #e3e6ef;
      border-radius: 4px;
    }
  }
}

.modal-container {
  :global {
    .ant-modal {
      .ant-modal-content {
        border-radius: 16px;
        padding-top: 8px;

        .ant-modal-close {
          padding: 8px;

          > span > span {
            background-color: #f4f5f7;
            padding: 8px;
            border-radius: 50%;
            font-size: 13px;
          }
        }

        .ant-modal-header {
          border-bottom: none;
          > div {
            font-weight: 500;
          }
        }

        .ant-modal-body {
          button:first-child {
            // background-color: #f1f6ff;
            margin-right: 10px;
          }

          button:last-child:not(.not-red) {
            background-color: #FF4D4F;
            margin-right: -7px;

            &:hover {
              background-color: #8e1404;
            }
          }
        }
      }
    }
  }
}
.tip-modal-container {
  :global {
    .ant-modal {
      .ant-modal-content {
        border-radius: 16px;

        .ant-modal-close {
          > span > span {
            background-color: #f4f5f7;
            padding: 8px;
            border-radius: 50%;
            font-size: 13px;
          }
        }

        .ant-modal-header {
          border-radius: 16px;
          border-bottom: none;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          padding-bottom: 2px;

          > div {
            font-weight: 500;
          }
        }

        .ant-card-body {
          padding: 0 !important;
          background-color: #fff;
        }

        .close-button {
          color: #c11500;
          background-color: #ffffff;
          margin-right: 10px;
          border: 1px solid #c11500;
        }

        .ant-form-item-explain-error {
          padding: 0px;
        }
      }
    }
  }
}
