.table-container {
  :global {
    .ant-card {
      .ant-card-body {
        padding: 10px 15px 15px !important;
      }
    }
  }
}

.sorting-icons {
  margin-left: 15px;

  @media only screen and (max-width: 650px) {
    margin-left: 0px;
  }

  &:hover {
    background-color: #f1f1f1;
  }

  height: 25px;
  width: 25px;
  border-radius: 50px;
  padding: 6px;
  cursor: pointer;

  @media only screen and (max-width: 350px) {
    height: 20px;
    width: 20px;
  }
}

.flex {
  display: flex;
  align-items: center;
  gap: 11px;
}

.filter-by {
  background-color: rgb(255, 255, 255) !important;
  box-shadow: 0px 3px 5px rgba(146, 153, 184, 0.05) !important;
  border-radius: 4px !important;
  &:hover,
  &:focus,
  &:active {
    background-color: rgb(255, 255, 255) !important;
  }
  padding: 10px 12px 10px 10px !important;
  height: 42px !important;
}

.overlay-content {
  padding: 0;

  :global {
    .ant-popover-inner {
      padding: 0;
      border-radius: 8px;
      .ant-popover-inner-content {
        border-radius: 8px;
        width: 180px;
        padding: 0;
        .ant-card {
          border-radius: 8px;
          width: 180px;
          .ant-card-head {
            padding: 10px;
            .ant-card-head-title {
              padding: 0px;
            }
          }

          .ant-card-body {
            padding: 2px 0px !important;
            ul {
              border-radius: 16px;
              li {
                div {
                  margin: 0;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                  color: #545454;
                }
              }
            }
          }
        }
      }
    }
  }
}
.modal-wrapper {
  :global {
    .ant-modal {
      .ant-modal-content {
        border-radius: 16px;
        padding-top: 8px;
        .ant-modal-close {
          padding: 8px;
          > span > span {
            background-color: #f4f5f7;
            padding: 8px;
            border-radius: 50%;
            font-size: 13px;
          }
        }
        .ant-modal-header {
          > div {
            font-weight: 500;
          }
        }
        .ant-modal-body {
          button:first-child {
            // background-color: #f1f6ff !important;
            margin-right: 10px;
          }
          /* button:last-child {
            background-color: #c11500;
            margin-right: -7px;
            &:hover {
              background-color: #8e1404;
            }
          } */
        }
      }
    }
  }
}
