@use "../../../styles/abstracts" as *;

.edit-team__header {
  padding: 18px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .team-info {
    display: flex;
    flex-direction: column;
  }

  .team-info__name {
    font-weight: 600;
    font-size: 16px;
    line-height: 18.4px;
    padding-bottom: 8px;
    color:$primary-color;
  }

  .team-info__email {
    font-weight: 400;
    font-size: 13px;
    color: $light-color;
    line-height: 14.95px;
  }

  span.team-info__status-text {
    height: 100%;
    font-size: 12px;
    padding: 0 12.41px;
    line-height: 1.9;
    font-weight: 500;
    border-radius: 12px;
    text-transform: capitalize;
    display: inline-block !important;
    background: #ddd;

    &.active {
      background-color: $success-color-bg;
      color: $success-color;
    }

    &.live {
      background-color: $success-color-bg;
      color: $success-color;
    }

    &.disabled {
      background-color: $grey-scale;
      color: $disabled;
    }

    &.invited {
      background-color: $warning-color-bg;
      color: $warning-color;
    }
  }
}