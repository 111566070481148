@use "../../../../styles/abstracts" as *;

.appointment-select {
  width: 100%;

  :global {
    .ant-select-selector {
      border-radius: 5px !important;
      border-color: #e2e2e2 !important;
      padding: 10px !important;
      min-height: 55px !important;
    }

    .ant-select-selection-placeholder {
      color: #9299b8;
    }

    .ant-select-arrow {
      width: 20px;
      color: #323232 !important;
    }
    .ant-select-clear {
      width: 20px;
      font-size: 20px;
    }
  }
}

.form-group {
  width: 100%;

  :global {
    .ant-space-item {
      width: 100%;
    }
    .ant-input::placeholder {
      color: #9299b8;
    }
  }
}

.taps-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  row-gap: 0.62rem;
  @media only screen and (max-width: 670px) {
    flex-direction: column;
    width: 100% !important;
  }
  :global {
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: #586b94 !important;
      color: #ffffff !important;
    }
    .ant-radio-button-wrapper {
      border-left: 1px solid #d9d9d9;
      display: flex;
      justify-content: center;
      border-radius: 2px 0 0 2px;
      color: #586b94;
      height: 48px;
      line-height: 46px;
      padding: 0px 25.25px;
      @media only screen and (max-width: 670px) {
        width: 100% !important;
        margin-bottom: 1%;
      }
    }
    .ant-radio-button-wrapper:not(:first-child):before {
      width: 0;
    }
  }
}

.tap {
  width: 18%;
  border-radius: 5px !important;
}
.session-tap {
  border-radius: 5px !important;
}

.discount-tap {
  width: 100%;
  border-radius: 5px !important;
}
.tip-input {
  margin: auto;
  width: 50%;
}

.wrapper {
  :global {
    .notification-box-single {
      @media only screen and (max-width: 450px) {
        margin-left: -1.8rem !important;
        width: 120%;
      }
    }
    .parking-cost {
      @media only screen and (max-width: 815px) {
        justify-content: center !important;
      }
    }
    .mark-up {
      @media only screen and (max-width: 815px) {
        display: flex;
        flex-direction: column;
      }
      @media only screen and (max-width: 600px) {
        margin-right: 2%;
      }
    }
    .ant-input-affix-wrapper {
      @media only screen and (max-width: 475px) {
        padding: 3px !important;
      }
      input {
        @media only screen and (max-width: 475px) {
          font-size: 12px;
          padding: 0px !important;
        }
      }
      @media only screen and (max-width: 600px) {
        max-width: fit-content;
      }
      @media only screen and (max-width: 475px) {
        font-size: 12px;
      }
    }
    .ant-switch-checked {
      background-color: #586b94;
    }
    .notification-box-single {
      width: 100%;
      .ant-btn {
        box-shadow: none;
      }
      .ant-card .ant-card-body {
        padding: 0 !important;
        margin-bottom: 0 !important;
      }
      .ant-card-bordered {
        border: none;
      }
      .notification-header {
        .notification-header__text {
          font-size: 15px;
          font-weight: 500;
        }
        .btn-toggle {
          font-size: 13px;
          background: none;
          border: none;
          cursor: pointer;
          color: $info-color;
        }
      }
      .custom-price-card {
        @media only screen and (max-width: 400px) {
          box-sizing: border-box;
          width: 100%;
        }
      }
      .notification-body {
        .ant-card {
          margin-bottom: 0 !important;
          @media only screen and (max-width: 475px) {
            padding: 0px !important;
          }
        }

        nav {
          // margin-top: 3rem;

          li {
            @media only screen and (max-width: 475px) {
              font-size: 12px;
            }
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0;
            padding: 0.5rem 0;
            @media only screen and (max-width: 575px) {
              padding: 15px 20px !important;
            }
            @media only screen and (max-width: 430px) {
              padding: 20px 0px !important;
              margin-left: 15px;
            }
            &:first-child {
              color: black;
              margin-bottom: 1rem;
              font-weight: 500;
            }
            &:not(:first-child):not(:last-child) {
              border-bottom: 1px solid $border-color-light;
            }
            & > *:first-child {
              flex: 70%;
            }
            & > *:nth-child(2) {
              flex: 26%;
            }
            & > *:last-child {
              flex: 4%;
            }
          }
        }
      }
      .notification-list-single {
        .notification-list-single__title {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 2px;
          color: $gray-color;
        }
        p {
          margin-bottom: 3px;
          color: $light-color;
        }
      }
      .col {
        display: flex;
        align-items: center;
      }
      .treatments-enable {
        display: flex;
        justify-content: flex-end;
      }
    }
    .notification-actions {
      margin: 26px 0 11px;
      button {
        border-radius: 6px;
        height: 44px;
        margin-bottom: 15px;
      }
    }
    .column-title .col {
      @media only screen and (max-width: 650px) {
        padding-right: 1%;
        text-align: center;
      }
      @media only screen and (max-width: 350px) {
        padding-right: 10px;
      }
    }
  }
}
