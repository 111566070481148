.modal-wrapper {
  :global {
    .ant-modal {
      .ant-modal-content {
        border-radius: 16px;
        padding-top: 8px;
        .ant-modal-header {
          border: none;
          display: flex;
          .ant-modal-title {
            margin: auto;
          }
          > div {
            font-weight: 500;
            font-size: 18px;
          }
        }
        .ant-modal-body {
          padding: 0;
          padding-bottom: 16px;
          font-weight: 400;
          font-size: 16px;
          text-align: center;
        }
        .ant-modal-footer {
          text-align: center;
        }
      }
    }
  }
}
