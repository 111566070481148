.image-overlay {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.padding-150 {
  padding: 150px 3vw;
}

.width-130 {
  width: 130px;
}

.margin-top-28 {
  margin-top: 28px;
}

.fullBg {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
  z-index: -3;
  opacity: 0.7;
}