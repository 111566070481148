@import "../../../styles/abstracts/variables";

.wrapper {
  :global {
    .ant-upload-list.ant-upload-list-picture {
      .ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture {
        border: none;
        .ant-upload-list-item-info .ant-upload-span {
          .ant-upload-list-item-card-actions {
            .ant-btn:hover span {
              color: #ff4d4f !important;
              scale: 1.5;
            }
          }
        }
      }
    }
    .ant-upload-list.ant-upload-list-text {
      .ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-text {
        border: none;
        .ant-upload-list-item-info .ant-upload-span {
          .ant-upload-list-item-card-actions {
            .ant-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only.ant-upload-list-item-card-actions-btn {
              opacity: 1;
            }
            .ant-btn:hover span {
              color: #ff4d4f !important;
              scale: 1.5;
            }
          }
        }
      }
    }
    .ant-upload.ant-upload-drag {
      background-color: $bg-color-light !important;
      height: 300px;
      border-spacing: 6px;
      border-width: 2px;
      border-radius: 10px;
      border-color: $border-color-deep;

      @media only screen and (max-width: 575px) {
        padding: 15px !important;
      }

      .ant-upload-drag-icon {
        i,
        svg {
          color: $blue-gray;
        }
      }

      .ant-upload-text {
        font-weight: 500;
        margin-bottom: 8px !important;
      }

      .ant-upload-hint {
        font-size: 15px;
        font-weight: 500;
        color: $navy-blue;

        span {
          color: $link-color-blue;
        }
      }

      .upload-progress {
        width: 100%;
        margin-top: 16px;
      }

      .progress-bar {
        height: 8px;
        width: 100%;
        background-color: #f0f0f0;
        border-radius: 4px;
      }

      .progress-text {
        margin-top: 4px;
        text-align: center;
      }
    }
  }
}
