$primary-color: #272b41;
$navy-blue: #5a5f7d;
$navy-accent: #586b94;
$light-blue-gray: #9299bb;
$blue-gray: #adb4d2;
$light-gray: #e3e6ef;
$opposite-primary-color: #ffffff;
$cactus-flower: #f58266;
$success-color: #20c997;
$success-color-bg: #20c99615;
$warning-color: #fa8b0c;
$warning-color-bg: #fa8b0b15;
$grey-scale: #f6f6f6;
$disabled: #afafaf;
$border-color-light: #f1f2f6;
$bg-color-light: #f8f9fb;
$light-color: #9299b8;
$info-color: #2c99ff;
$danger-color: #ff4d4f;
$danger-color-bg: #ff4d4e15;
$black: #000;
$link-color: #5f63f2;
$blue-subtle: #f1f7ff;
$dark-blue: #39455e;
$highlight-color: #f1f6ff;
$gray-color: #5a5f7d;
$extra-light-color: #adb4d2;
$dark-color: #272b41;
$soothe-color: #586b94;
$secondary-color: #ff69a5;
$light-gray-color: #868eae;
$border-color-deep: #c6d0dc;
$link-color-blue: #1890ff;
$border-color-base: #d9d9d9;
$bgGrayDeep-color: #eff0f3;
$pink-color: #f63178;
$text-gray-color: #545454;
$border-color-normal: #e3e6ef;
