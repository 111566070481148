.properties-container {
  background: #FFFFFF;
  border-radius: 16px;
  box-shadow: 0px 5px 20px rgba(146, 153, 184, 0.031);
  margin-top: -15px;

  :global {
    .ant-card {
      .ant-card-body {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }
}